import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, throwError, Observable } from 'rxjs';
import { catchError, mapTo, switchMap, take, tap } from 'rxjs/operators';

import * as availableTerritoriesActions from '../store/actions/available-teritoties.actions';
import { State } from '../store/reducers/core.reducers';
import { getAvailableTerritories } from '../store/selectors/available-teritories.selector';

import { AvailableTerritoriesService } from '../services/available-territories.service';

import { ITerritories } from '../../../models';

@Injectable()
export class AvailableTerritoriesExistGuard  {

  constructor(private store: Store<State>,
              private service: AvailableTerritoriesService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getAvailableTerritories),
      take(1),
      switchMap((territories) => {
        return territories
          ? of(true)
          : this.service.getAvailable()
            .pipe(
              tap((_territories: ITerritories) => this.store.dispatch(availableTerritoriesActions
                .getAvailableTerritoriesSuccessAction(_territories))),
              mapTo(true),
              catchError((error: any) => {
                this.store.dispatch(availableTerritoriesActions.getAvailableTerritoriesErrorAction(error));
                return throwError(error);
              })
            );
      }),
      catchError(error => of(false))
    );
  }
}
