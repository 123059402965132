export enum AdminRoles {
  Admin = 'admin',
  Super = 'super_admin',
  Update = 'update_admin',
  Sales = 'sales_admin'
}

export class AdminRole {
  id: AdminRoles;
  name: string;
  permissions: IPermissions;
  groups: string[];
}

export interface IPermissions {
  [key: string]: boolean;
}

export const ADMIN_ROLES_MAP: { [key: string]: AdminRole } = {
  [AdminRoles.Admin]: {
    id:          AdminRoles.Admin,
    name:        'Admin',
    permissions: {
      editCustomer: false,
    },
    groups:      []
  },
  [AdminRoles.Super]: {
    id:          AdminRoles.Super,
    name:        'Super Admin',
    permissions: {
      editCustomer: true,
    },
    groups:      []
  },
  [AdminRoles.Update]: {
    id:          AdminRoles.Update,
    name:        'Update Admin',
    permissions: {
      editCustomer: true,
    },
    groups:      []
  },
  [AdminRoles.Sales]: {
    id:          AdminRoles.Sales,
    name:        'Sales Admin',
    permissions: {
      editCustomer: false,
    },
    groups:      []
  }
};

export const ADMIN_ROLES = (<any>Object).keys(ADMIN_ROLES_MAP).map(role => ADMIN_ROLES_MAP[role]);

