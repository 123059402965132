import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/administrators.action';

import { Administrator } from '../../../../models';

export interface State {
  administrators: Administrator[];

  administratorsLoading: boolean;
  administratorsLoaded: boolean;
  administratorsLoadingError: string | null;

  administratorRemoving: boolean;
  administratorRemovingError: string | null;
  removingAdministratorId: number | null;

  administratorUpdating: boolean;
  administratorUpdatingError: string | null;
  updatingAdministratorId: number | null;

  administratorCreating: boolean;
  administratorCreatingError: string | null;
}

const initialState: State = {
  administrators: [],
  administratorsLoading: false,
  administratorsLoaded: false,
  administratorsLoadingError: null,

  administratorRemoving: false,
  administratorRemovingError: null,
  removingAdministratorId: null,

  administratorUpdating: false,
  administratorUpdatingError: null,
  updatingAdministratorId: null,

  administratorCreating: false,
  administratorCreatingError: null,
};

const getAdministrators = (state: State): State => ({
  ...state,
  administratorsLoading: true
});

const getAdministratorsError = (state: State, { payload }): State => ({
  ...state,
  administratorsLoading: false,
  administratorsLoaded: false,
  administratorsLoadingError: payload.error
});

const getAdministratorsSuccess = (state: State, { payload }): State => ({
  ...state,
  administratorsLoading: false,
  administratorsLoaded: true,
  administratorsLoadingError: null,
  administrators: [...payload]
});

const removeAdministrator = (state: State, { payload }): State => ({
  ...state,
  administratorRemoving: true,
  removingAdministratorId: payload.id
});

const removeAdministratorError = (state: State, { payload }): State => ({
  ...state,
  administratorRemoving: false,
  administratorRemovingError: payload.error,
  removingAdministratorId: null
});

const removeAdministratorSuccess = (state: State, { payload }): State => ({
  ...state,
  administratorRemoving: false,
  administratorRemovingError: null,
  removingAdministratorId: null,
  administrators: state
    .administrators
    .filter(administrator => administrator.id !== payload.id)
});

const updateAdministrator = (state: State, { payload }): State => ({
  ...state,
  administratorUpdating: true,
  updatingAdministratorId: payload.id
});

const updateAdministratorError = (state: State, { payload }): State => ({
  ...state,
  administratorUpdating: false,
  administratorUpdatingError: payload.error,
  updatingAdministratorId: null
});

const updateAdministratorSuccess = (state: State, { payload }): State => ({
  ...state,
  administratorUpdating: false,
  administratorUpdatingError: null,
  updatingAdministratorId: null,
  administrators: state.administrators
    .map(administrator => administrator.id === payload.id
      ? { ...payload }
      : { ...administrator })
});

const createAdministrator = (state: State): State => ({
  ...state,
  administratorCreating: true
});

const createAdministratorError = (state: State, { payload }): State => ({
  ...state,
  administratorCreating: false,
  administratorCreatingError: payload.error.message
});

const createAdministratorSuccess = (state: State, { payload }): State => ({
  ...state,
  administratorCreating: false,
  administratorCreatingError: null,
  administrators: [
    ...state.administrators,
    { ...payload }
  ]
});

const administratorsReducer = createReducer<State>(
  initialState,

  on(actions.getAdministratorsAction, getAdministrators),
  on(actions.getAdministratorsErrorAction, getAdministratorsError),
  on(actions.getAdministratorsSuccessAction, getAdministratorsSuccess),

  on(actions.removeAdministratorAction, removeAdministrator),
  on(actions.removeAdministratorErrorAction, removeAdministratorError),
  on(actions.removeAdministratorSuccessAction, removeAdministratorSuccess),

  on(actions.updateAdministratorAction, updateAdministrator),
  on(actions.updateAdministratorErrorAction, updateAdministratorError),
  on(actions.updateAdministratorSuccessAction, updateAdministratorSuccess),

  on(actions.createAdministratorAction, createAdministrator),
  on(actions.createAdministratorErrorAction, createAdministratorError),
  on(actions.createAdministratorSuccessAction, createAdministratorSuccess)
);

export function reducer(state: State, action: Action): State {
  return administratorsReducer(state, action);
}

export const administrators = (state: State) => state.administrators;
export const administratorRemoving = (state: State) => state.administratorRemoving;
export const removingAdministratorId = (state: State) => state.removingAdministratorId;
export const administratorUpdating = (state: State) => state.administratorUpdating;
export const updatingAdministratorId = (state: State) => state.updatingAdministratorId;
export const administratorCreatingError = (state: State) => state.administratorCreatingError;
export const administratorsLoaded = (state: State) => state.administratorsLoaded;

