import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Go } from '../store/actions/router.action';
import { State } from '../store/reducers/core.reducers';
import { getAdminProfile } from '../store/selectors/profile.selector';

import { AdminProfile, AdminRoles } from '../../../models';

@Injectable()
export class SuperAdminGuard  {

  constructor(private store: Store<State>) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .select(getAdminProfile)
      .pipe(
        take(1),
        map((profile: AdminProfile) => !!profile && profile.role && profile.role.id === AdminRoles.Super),
        tap((isSuperAdmin: boolean) => !isSuperAdmin && this.store.dispatch(Go(['/404'])))
      );
  }
}
