import { createAction } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

import { Administrator, NewAdministrator } from '../../../../models';

enum ActionTypes {
  GET_ADMINISTRATORS = '[Administrators] Get Administrators',
  GET_ADMINISTRATORS_ERROR = '[Administrators] Get Administrators Error',
  GET_ADMINISTRATORS_SUCCESS = '[Administrators] Get Administrators Success',

  REMOVE_ADMINISTRATOR = '[Administrators] Remove Administrator',
  REMOVE_ADMINISTRATOR_ERROR = '[Administrators] Remove Administrator Error',
  REMOVE_ADMINISTRATOR_SUCCESS = '[Administrators] Remove Administrator Success',

  UPDATE_ADMINISTRATOR = '[Administrators] Update Administrator',
  UPDATE_ADMINISTRATOR_ERROR = '[Administrators] Update Administrator Error',
  UPDATE_ADMINISTRATOR_SUCCESS = '[Administrators] Update Administrator Success',

  CREATE_ADMINISTRATOR = '[Administrators] Create Administrator',
  CREATE_ADMINISTRATOR_ERROR = '[Administrators] Create Administrator Error',
  CREATE_ADMINISTRATOR_SUCCESS = '[Administrators] Create Administrator Success',
}

export const getAdministratorsAction = createAction(ActionTypes.GET_ADMINISTRATORS);
export const getAdministratorsErrorAction = createAction(ActionTypes.GET_ADMINISTRATORS_ERROR, createPayload<{ error: string }>());
export const getAdministratorsSuccessAction = createAction(ActionTypes.GET_ADMINISTRATORS_SUCCESS,
  createPayload<Array<Administrator>>());

export const removeAdministratorAction = createAction(ActionTypes.REMOVE_ADMINISTRATOR, createPayload<Administrator>());
export const removeAdministratorErrorAction = createAction(ActionTypes.REMOVE_ADMINISTRATOR_ERROR, createPayload<{ error: string }>());
export const removeAdministratorSuccessAction = createAction(ActionTypes.REMOVE_ADMINISTRATOR_SUCCESS, createPayload<Administrator>());

export const updateAdministratorAction = createAction(ActionTypes.UPDATE_ADMINISTRATOR, createPayload<Administrator>());
export const updateAdministratorErrorAction = createAction(ActionTypes.UPDATE_ADMINISTRATOR_ERROR, createPayload<{ error: string }>());
export const updateAdministratorSuccessAction = createAction(ActionTypes.UPDATE_ADMINISTRATOR_SUCCESS, createPayload<Administrator>());

export const createAdministratorAction = createAction(ActionTypes.CREATE_ADMINISTRATOR, createPayload<NewAdministrator>());
export const createAdministratorErrorAction = createAction(ActionTypes.CREATE_ADMINISTRATOR_ERROR,
  createPayload<{ error: { message: string, errors: Array<string> }, status: number, admin: NewAdministrator }>());
export const createAdministratorSuccessAction = createAction(ActionTypes.CREATE_ADMINISTRATOR_SUCCESS, createPayload<Administrator>());
