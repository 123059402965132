import { createAction } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

import { IAdminResponse, ITokenResponse } from '../../../../models';

enum ActionTypes {
  GET_ADMIN_PROFILE = '[PROFILE]: get admin profile',
  GET_ADMIN_PROFILE_ERROR = '[PROFILE]: get admin profile error',
  GET_ADMIN_PROFILE_SUCCESS = '[PROFILE]: get admin profile success',

  UPDATE_ADMIN_PROFILE = '[PROFILE]: update admin profile',
  UPDATE_ADMIN_PROFILE_ERROR = '[PROFILE]: update admin profile error',
  UPDATE_ADMIN_PROFILE_SUCCESS = '[PROFILE]: update admin profile success',

  LOGIN_AS_MYSELF = '[PROFILE] Login As Myself',
  LOGIN_AS_MYSELF_SUCCESS = '[PROFILE] Login As Myself Success',
  LOGIN_AS_MYSELF_ERROR = '[PROFILE] Login As Myself Error',
}

export const getAdminProfileAction  = createAction(ActionTypes.GET_ADMIN_PROFILE);
export const getAdminProfileErrorAction  = createAction(ActionTypes.GET_ADMIN_PROFILE_ERROR,
  createPayload<{ error: { message: string } }>());
export const getAdminProfileSuccessAction  = createAction(ActionTypes.GET_ADMIN_PROFILE_SUCCESS,
  createPayload<{ admin: IAdminResponse }>());

export const updateAdminProfileAction  = createAction(ActionTypes.UPDATE_ADMIN_PROFILE, createPayload<IAdminResponse>());
export const updateAdminProfileErrorAction = createAction(ActionTypes.UPDATE_ADMIN_PROFILE_ERROR,
  createPayload<{ error: { message: string } }>());
export const updateAdminProfileSuccessAction = createAction(ActionTypes.UPDATE_ADMIN_PROFILE_SUCCESS, createPayload<IAdminResponse>());

export const loginAsMyselfAction = createAction(ActionTypes.LOGIN_AS_MYSELF);
export const loginAsMyselfErrorAction = createAction(ActionTypes.LOGIN_AS_MYSELF_ERROR, createPayload<any>());
export const loginAsMyselfSuccessAction = createAction(ActionTypes.LOGIN_AS_MYSELF_SUCCESS, createPayload<ITokenResponse>());
