 import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './pages/main-page/main-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { AvailableTerritoriesExistGuard } from './guards/available-territories-exist.guard';
import { SuperAdminGuard } from './guards/super-admin.guard';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
 import { ProfileExistGuard } from './guards/profile-exist.guard';

const APP_ROUTES: Routes = [{
  path: 'login/callback',
  component: OktaCallbackComponent,
}, {
  path: '',
  pathMatch: 'full',
  redirectTo: 'main'
}, {
    path: 'login',
    component: OktaCallbackComponent,
},
  {
  path: 'logout',
  pathMatch: 'full',
  redirectTo: 'main'
}, {
  path: 'main',
  component: MainPageComponent,
  canActivate: [OktaAuthGuard],
  canActivateChild: [ProfileExistGuard],
  children: [{
    path: '',
    redirectTo: 'customers',
    pathMatch: 'prefix'
  }, {
    path: 'customers',
    loadChildren: () => import('./../customers/customers.module')
      .then(m => m.CustomersModule),
    canActivate: [AvailableTerritoriesExistGuard]
  }, {
    path: 'user',
    loadChildren: () => import('../edit-profile/edit-profile.module')
      .then(m => m.EditProfileModule)
  }, {
    path: 'administrators',
    loadChildren: () => import('./../administrators/administrators.module')
      .then(m => m.AdministratorsModule),
    canActivate: [SuperAdminGuard, AvailableTerritoriesExistGuard],
  }, 
  {
    path: 'account-list',
    loadChildren: () => import('./../account-list/account-list.module')
      .then(m => m.AccountListModule),
    // canActivate: [SuperAdminGuard, AvailableTerritoriesExistGuard],
  }
]
}, {
  path: '**',
  component: PageNotFoundComponent,
  canActivate: [OktaAuthGuard],
}];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [
    SuperAdminGuard,
    AvailableTerritoriesExistGuard,
    ProfileExistGuard
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutesModule {
}
