import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';

import { pluck } from 'rxjs/operators';

import { Go } from '../../store/actions/router.action';
import { State } from '../../store/reducers/core.reducers';
import { getAdminProfile } from '../../store/selectors/profile.selector';

import { ResizeService } from '../../../resize/resize.service';


import { routerAnimation } from '../../../../utils/page.animation';

import { AdminRoles } from '../../../../models';
import { environment } from '../../../../../environments/environment';
import { Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'bl-cms-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [routerAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPageComponent implements AfterViewInit {
  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  // Applying theme class
  @HostBinding('class.dark-theme') darkTheme = false;
  _sidenavMode = 'push';
  _boxedLayout = false;
  sideNavOpened = false;
  // TODO move to config;
  // Data for messages at popup
  messages = []; // TODO Messages service
  // admin profile
  readonly adminRoles = AdminRoles;
  adminProfileRole$ = this.store.select(getAdminProfile)
    .pipe(pluck('role'));

  LOGOUT_REDIRECT_URI = environment.keys.oktaLogoutConfig.redirectUri;

  constructor(public resizeService: ResizeService, private store: Store<State>,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
    this.onResize();
  }

  items = [{
    title: 'My Account',
    routerLinkUrl: '/main/user/profile',
    flag: 'always',
    showItem: true,
    icon: 'account_box'
  },
  {
    title: 'Administrators',
    flag: this.adminRoles.Super,
    routerLinkUrl: '/main/administrators/list',
    showItem: true,
    icon: 'supervisor_account'
  },
  {
    title: 'Users list',
    routerLinkUrl: '/main/customers/list',
    flag: this.adminRoles.Sales,
    showItem: true,
    icon: 'format_align_left'
  },
  {
    title: 'Account list',
    routerLinkUrl: '/main/account-list/list',
    flag: this.adminRoles.Sales,
    showItem: true,
    icon: 'format_align_left',
  }];

  generateItems(id) {
    return this.items.filter((item) => {
      return item.showItem = this.removeItem(item, id);
    })
  }

  removeItem(item, id): boolean {
    switch (item.icon) {

      case 'format_align_left': {
        return item.flag !== id;
      }

      case 'supervisor_account': {
        return item.flag === id;
      }

      default: {
        return true;
      }

    }
  }

  ngAfterViewInit(): void {
    // Resize after sidenav open on startup to draw charts correctly
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
    // setTimeout(() => this.sideNavOpened = true, 0);
  }

  goToProfile(): void {
    this.store.dispatch(Go(['/main', 'user']));
  }

  async logout(): Promise<void> {
    await this._oktaAuth.signOut({
      postLogoutRedirectUri: this.LOGOUT_REDIRECT_URI
    });
  }

  set sidenavMode(val: string) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get sidenavMode(): string {
    return this._sidenavMode;
  }

  set boxedLayout(val: boolean) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get boxedLayout(): boolean {
    return this._boxedLayout;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth < 800) {
      this.sideNavOpened = false;
      this._sidenavMode = 'over';
    }
  }
}
