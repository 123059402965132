import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/customers.action';

import { ICustomers, SHOW_USERS } from '../../../../models';

export interface State {
  customers: ICustomers;
  loaded: boolean;
  loading: boolean;
  error?: string;

  showUsers: SHOW_USERS;

  customerLoadingId: number;
  customerToken: string;
  isCustomerTokenLoading: boolean;
  isCustomerTokenLoaded: boolean;
  tokenByIdError?: string;
  tokenByEmailError?: string;

  customerRegisterStatusIsLoading: boolean;
  customerRegisterStatusEmail: string;
  customerRegisterStatus: boolean;
  customerRegisterStatusError: string;
} // TDOO move customer to different state

const initialState: State = {
  customers: [],
  loaded: false,
  loading: false,

  showUsers: SHOW_USERS.CUSTOMER,

  customerLoadingId: null,
  customerToken: null,
  isCustomerTokenLoaded: false,
  isCustomerTokenLoading: false,

  customerRegisterStatusIsLoading: false,
  customerRegisterStatusEmail: null,
  customerRegisterStatus: null,
  customerRegisterStatusError: null
};

const pending = (state: State): State => ({
  ...state,
  loading: true
});

const getCustomersError = (state: State, { payload }): State => ({
  ...state,
  customers: initialState.customers,
  error: payload.error.message,
  loading: false
});

const updateCustomerError = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload
});

const updateCustomerSuccess = (state: State, { payload }): State => ({
  ...state,
  loading: false,
  customers: state.customers
    .map(customer => customer.id === payload.id
      ? { ...customer, ...payload }
      : customer)
});

const getCustomersSuccess = (state: State, { payload }): State => ({
  ...state,
  customers: [...payload.customers],
  loading: false,
  loaded: true,
});

const getTokenForCustomerSignIn = (state: State, { payload }): State => ({
  ...state,
  customerLoadingId: payload.customerId,
  isCustomerTokenLoading: true
});

const getTokenForCustomerSignInError = (state: State, { payload }): State => ({
  ...state,
  tokenByIdError: payload.error.message,
  isCustomerTokenLoading: false,
  isCustomerTokenLoaded: false,
});

const getTokenForCustomerSignInSuccess = (state, { payload }) => ({
  ...state,
  customerToken: payload.customerToken,
  isCustomerTokenLoading: false,
  isCustomerTokenLoaded: true
});

const getCustomerRegisterStatus = (state: State, { payload }): State => ({
  ...state,
  customerRegisterStatusIsLoading: true,
  customerRegisterStatusEmail: payload.email,
  customerRegisterStatus: null,
  customerRegisterStatusError: null
});

const getCustomerRegisterStatusError = (state: State, { payload }): State => ({
  ...state,
  customerRegisterStatusIsLoading: false,
  customerRegisterStatus: null,
  customerRegisterStatusError: payload.error.message
});

const getCustomerRegisterStatusSuccess = (state: State, { payload }): State => ({
  ...state,
  customerRegisterStatusIsLoading: false,
  customerRegisterStatus: payload.customerExist,
  customerRegisterStatusError: null
});

const saveCustomerRegisterStatusEmail = (state: State, { payload }): State => ({
  ...state,
  customerRegisterStatus: null,
  customerRegisterStatusError: null,
  customerRegisterStatusIsLoading: false,
  customerRegisterStatusEmail: payload.email || null
});

const switchShowUsers = (state: State, { payload }): State => ({
  ...state,
  showUsers: payload,
  customerRegisterStatus: null,
  customerRegisterStatusError: null,
  customerRegisterStatusIsLoading: false
});

const customerReducer = createReducer<State>(
  initialState,

  on(
    actions.getCustomersAction,
    actions.updateCustomerAction,
    pending
  ),

  on(actions.getCustomersErrorAction, getCustomersError),
  on(actions.updateCustomerErrorAction, updateCustomerError),

  on(actions.updateCustomerSuccessAction, updateCustomerSuccess),

  on(actions.getCustomersSuccessAction, getCustomersSuccess),

  on(actions.getTokenForCustomerSignInAction, getTokenForCustomerSignIn),
  on(actions.getTokenForCustomerSignInErrorAction, getTokenForCustomerSignInError),
  on(actions.getTokenForCustomerSignInSuccessAction, getTokenForCustomerSignInSuccess),

  on(actions.getCustomerRegisterStatusAction, getCustomerRegisterStatus),
  on(actions.getCustomerRegisterStatusErrorAction, getCustomerRegisterStatusError),
  on(actions.getCustomerRegisterStatusSuccessAction, getCustomerRegisterStatusSuccess),

  on(actions.saveCustomerRegisterStatusEmailAction, saveCustomerRegisterStatusEmail),

  on(actions.switchShowUsersAction, switchShowUsers)
);

export function reducer(state: State,
                        action: Action): State {
  return customerReducer(state, action);
}

export const getCustomers = (state: State) => state.customers;
export const isLoading = (state: State) => state.loading;
export const isLoaded = (state: State) => state.loaded;
export const showUsers = (state: State): SHOW_USERS => state.showUsers;
export const customerLoadingId = (state: State) => state.customerLoadingId;
export const getTokenByEmailError = (state: State) => state.tokenByEmailError;
export const customerToken = (state: State) => state.customerToken;
export const isCustomerTokenLoading = (state: State) => state.isCustomerTokenLoading;

export const isCustomerRegisterStatusLoading = (state: State) => state.customerRegisterStatusIsLoading;
export const customerRegisterStatusEmail = (state: State) => state.customerRegisterStatusEmail;
export const customerRegisterStatusError = (state: State) => state.customerRegisterStatusError;
export const customerRegisterStatus = (state: State) => state.customerRegisterStatus;
