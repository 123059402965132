import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from "@okta/okta-angular";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
  }

  applyCredentials(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.oktaAuth.getAccessToken();

    const headers = {
      'Authorization': token ? 'Bearer ' + token : ''
    };

    return req.clone({
      setHeaders: headers
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(this.applyCredentials(req))
      .pipe(catchError((error: HttpEvent<any>) => throwError(error)));
  }
}
