import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { State } from '../store/reducers/core.reducers';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, take, tap, } from 'rxjs/operators';
import { AdminProfile, AdminRoles, IAdminResponse } from '../../../models';
import { getAdminProfile } from '../store/selectors/profile.selector';
import * as profileActions from '../store/actions/profile.actions';
import { ProfileService } from '../services/profile.service';
import { Go } from '../store/actions/router.action';

@Injectable()
export class ProfileExistGuard  {
  constructor(private store: Store<State>,
              private profileService: ProfileService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getAdminProfile),
      take(1),
      switchMap((adminProfile: AdminProfile) => {
        return adminProfile
          ? of(true)
          : this.profileService.getProfile()
            .pipe(
              tap((admin: IAdminResponse) => {
                this.store.dispatch(profileActions.getAdminProfileSuccessAction({ admin }));
                if((admin.role == AdminRoles.Admin) || (admin.role == AdminRoles.Super) || (admin.role == AdminRoles.Update)){
                  // same for account lists too
                  // https://dnbenterprise.atlassian.net/browse/CCP-338 subpoint-3 of point-1
                  this.store.dispatch(Go(['/main/customers/list']))
                }
                else if((admin.role == AdminRoles.Sales)){
                  this.store.dispatch(Go(['/main/user/profile']))
                }
                else{
                  this.store.dispatch(Go(['/404']))
                }
              }),
              mapTo(true),
              catchError((error: any) => {
                this.store.dispatch(profileActions.getAdminProfileErrorAction({ error: error.message }));
                return throwError(error);
              })
            );
      }),
      catchError(() => of(false))
    );
  }
}
