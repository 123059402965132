import { AdminRole, AdminRoles, ADMIN_ROLES_MAP } from './admin-roles';

export interface IAdminResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export interface ITokenResponse {
  token: string;
}

export class AdminProfile {
  id: number;
  firstName = 'Admin';
  lastName: string;
  email: string;
  role: AdminRole;

  constructor(admin?: IAdminResponse) {
    if (admin) {
      this.id = admin.id;
      this.firstName = admin.firstName;
      this.lastName = admin.lastName;
      this.email = admin.email;
      this.role = ADMIN_ROLES_MAP[admin.role] || ADMIN_ROLES_MAP[AdminRoles.Admin];
    }
  }
}
