// core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// app core
import { CoreModule } from './modules/core/core.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterModule } from '@progress/kendo-angular-filter';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DialogsModule } from '@progress/kendo-angular-dialog';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports:      [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    FilterModule,
    DialogsModule,
  ],
  providers:    [
    provideAnimationsAsync()
  ],
  bootstrap:    [AppComponent]
})
export class AppModule {
}
