// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production:     true,
  envName:        'development',
  customerAppUrl: 'https://build-a-list-test.com/auth',
  asAdmin: 'login-as-admin',
  asMyself: 'login-as-myself',
  keys: {
    oktaLoginConfig: {
      clientId: '0oawdmt7jJjB4eU1k1d6',
      issuer: 'https://dev.sso.dnb.com/oauth2/aushed0qugr8vCoum1d6',
      redirectUri: '/login/callback',
      scopes: ['openid'],
      pkce: true
    },
    oktaLogoutConfig: {
      redirectUri: 'https://cms.build-a-list-test.com/logout',
    }
  },
  api:            {
    auth:      {
      login:        'https://api.build-a-list-test.com/dev/api/v1/admin/login',
      loginAsUser:  'https://api.build-a-list-test.com/dev/api/v1/admin/login-as-user',
      refreshToken: 'https://api.build-a-list-test.com/dev/api/v1/admin/refresh-token',
      loginAsMyself: 'https://api.build-a-list-test.com/dev/api/v1/admin/login-as-myself',
      forgot: 'https://api.build-a-list-test.com/dev/api/v1/admin/password/reset',
      reset: 'https://api.build-a-list-test.com/dev/api/v1/admin/password/verify-reset'
    },
    profile:   {
      base: 'https://api.build-a-list-test.com/dev/api/v1/admin/',
      territories: 'territories',
      get:    'https://api.build-a-list-test.com/dev/api/v1/admin/profile',
      update: 'https://api.build-a-list-test.com/dev/api/v1/admin/profile'
    },
    customers: {
      get:      'https://api.build-a-list-test.com/dev/api/v1/admin/customers',
      getToken: 'https://api.build-a-list-test.com/dev/api/v1/admin/login-as-user',
      checkCustomerRegisterStatus: 'https://api.build-a-list-test.com/dev/api/v1/admin/customers/check',
      update: 'https://api.build-a-list-test.com/dev/api/v1/admin/customers'
    },
    administrators: {
      main: 'https://api.build-a-list-test.com/dev/api/v1/admin/administrators',
    },
    accountlist: {
      main: 'https://api.build-a-list-test.com/dev/api/v1/admin/accounts',
    }
  }
};
