import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { reducer, State } from './administrators.reducer';

export interface IAdministratorsState {
  administrator: State;
}

const administratorsState: ActionReducerMap<IAdministratorsState> = {
  administrator: reducer
};

export const ADMINISTRATORS_STATE_NAME = 'ADMINISTRATORS';
export const ADMINISTRATORS_STATE = new InjectionToken<ActionReducerMap<IAdministratorsState>>(ADMINISTRATORS_STATE_NAME,
  { factory: () => administratorsState });
