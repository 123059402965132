<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="sidenavMode"
               [opened]="sideNavOpened" class="left-sidenav"
               (opened)="resizeService.resizeInformer$.next()"
               (close)="resizeService.resizeInformer$.next()">
    <header class="sidenav-header" >
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon color="primary" fontSet="fontawesome" fontIcon="fa-long-arrow-left fa-sm"></mat-icon>
      </button>

      <div class="text-center">
        <a (click)="sidenav.toggle()" [routerLink]="['/main', 'customers', 'list']">
          <!--<img *ngIf="darkTheme; else darkLogo" src="/assets/logo/logo-text-white.png">-->
          <ng-template #darkLogo>
            <!--<img src="/assets/logo/logo-text-black.png">-->
          </ng-template>
        </a>
      </div>
    </header>

      <bl-cms-px-gdn-sidemenu>
        <bl-cms-px-gdn-sideitem [items]="generateItems((adminProfileRole$ | async)?.id)" (click)="sidenav.toggle()">
        </bl-cms-px-gdn-sideitem>
      </bl-cms-px-gdn-sidemenu>

  </mat-sidenav>

  <mat-toolbar color="primary">

    <button mat-icon-button (click)="sidenav.toggle()" aria-label="Click to toggle Users Menu">
      <mat-icon fontSet="fontawesome" fontIcon="fa-bars fa-sm"></mat-icon>
    </button>

    <a [routerLink]="['/main', 'customers', 'list']" class="logo" aria-label="Click here to go HOME">
      <img *ngIf="darkTheme; else darkLogo" src="/assets/logo/logo-text-white.png">
      <!-- use logo.svg when it will be fixed -->
      <ng-template #darkLogo>
        <img src="/assets/logo/logo-text-black.png">
      </ng-template>
    </a>

    <span></span>

    <button mat-icon-button class="left_menu_tool_bar" [matMenuTriggerFor]="settingsMenu" aria-label="Click to toggle Settings Menu">
      <mat-icon fontSet="fontawesome" fontIcon="fa-ellipsis-v"></mat-icon>
    </button>

    <mat-menu class="custom-mat-menu" #settingsMenu="matMenu">
      <button mat-menu-item (click)="goToProfile()">
          <mat-icon fontSet="fontawesome" fontIcon="fa-sign-out"></mat-icon>
          My Account
      </button>

      <button mat-menu-item (click)="logout()">
        <mat-icon fontSet="fontawesome" fontIcon="fa-sign-out"></mat-icon>
        Sign-Out
      </button>
    </mat-menu>
  </mat-toolbar>

  <div class="wrapper" [class.wrapper-box]="boxedLayout">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
