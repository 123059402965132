import { createAction } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

import {
  ICustomers,
  IGetCustomerPayload,
  IUpdateCustomerPayload,
  SHOW_USERS
} from '../../../../models';

enum ActionTypes {
  GET_CUSTOMERS = '[CUSTOMERS] Get Customers',
  GET_CUSTOMERS_ERROR = '[CUSTOMERS] Get Customers error',
  GET_CUSTOMERS_SUCCESS = '[CUSTOMERS] Get Customers success',

  GET_TOKEN_FOR_CUSTOMER_SIGN_IN = '[CUSTOMERS] Get token for customer sign in',
  GET_TOKEN_FOR_CUSTOMER_SIGN_IN_SUCCESS = '[CUSTOMERS] Get token for customer sign in success',
  GET_TOKEN_FOR_CUSTOMER_SIGN_IN_ERROR = '[CUSTOMERS] Get token for customer sign in error',

  GET_CUSTOMER_REGISTER_STATUS = '[CUSTOMERS] Get register status',
  GET_CUSTOMER_REGISTER_STATUS_SUCCESS = '[CUSTOMERS] Get register status success',
  GET_CUSTOMER_REGISTER_STATUS_ERROR = '[CUSTOMERS] Get register status error',

  SAVE_CUSTOMER_REGISTER_STATUS_EMAIL = '[CUSTOMERS] Save customer register status email',

  SWITCH_SHOW_USERS = '[CUSTOMERS] Switch show users',

  UPDATE_CUSTOMER = '[CUSTOMERS] Update customer',
  UPDATE_CUSTOMER_ERROR = '[CUSTOMERS] Update customer error',
  UPDATE_CUSTOMER_SUCCESS = '[CUSTOMERS] Update customer success'
}

export const getCustomersAction = createAction(ActionTypes.GET_CUSTOMERS, createPayload<IGetCustomerPayload>());
export const getCustomersErrorAction = createAction(ActionTypes.GET_CUSTOMERS_ERROR, createPayload<any>());
export const getCustomersSuccessAction = createAction(ActionTypes.GET_CUSTOMERS_SUCCESS, createPayload<{ customers: ICustomers }>());

export const getTokenForCustomerSignInAction = createAction(ActionTypes.GET_TOKEN_FOR_CUSTOMER_SIGN_IN,
  createPayload<{ customerId: number }>());
export const getTokenForCustomerSignInSuccessAction = createAction(ActionTypes.GET_TOKEN_FOR_CUSTOMER_SIGN_IN_SUCCESS,
  createPayload<{ customerToken: string }>());
export const getTokenForCustomerSignInErrorAction = createAction(ActionTypes.GET_TOKEN_FOR_CUSTOMER_SIGN_IN_ERROR, createPayload<any>());

export const getCustomerRegisterStatusAction = createAction(ActionTypes.GET_CUSTOMER_REGISTER_STATUS, createPayload<{ email: string }>());
export const getCustomerRegisterStatusErrorAction = createAction(ActionTypes.GET_CUSTOMER_REGISTER_STATUS_ERROR,
  createPayload<{ error: { message: string } }>());
export const getCustomerRegisterStatusSuccessAction = createAction(ActionTypes.GET_CUSTOMER_REGISTER_STATUS_SUCCESS,
  createPayload<{ customerExist: boolean }>());
export const saveCustomerRegisterStatusEmailAction  = createAction(ActionTypes.SAVE_CUSTOMER_REGISTER_STATUS_EMAIL,
  createPayload<{ email: string }>());

export const switchShowUsersAction = createAction(ActionTypes.SWITCH_SHOW_USERS, createPayload<SHOW_USERS>());

export const updateCustomerAction  = createAction(ActionTypes.UPDATE_CUSTOMER, createPayload<IUpdateCustomerPayload>());
export const updateCustomerErrorAction  = createAction(ActionTypes.UPDATE_CUSTOMER_ERROR, createPayload<any>());
export const updateCustomerSuccessAction  = createAction(ActionTypes.UPDATE_CUSTOMER_SUCCESS, createPayload<IUpdateCustomerPayload>());
