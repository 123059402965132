@for (item of items; track item) {
<mat-list-item [ngClass]="{close: closeState == 'close', open: closeState == 'open'}" class="menu-level-{{menuLevel}} side-item-custom">
  <span class="item">
    <a mat-button (click)="toggleState(children.children.length != 0)"
      [routerLink]="item.routerLinkUrl ? item.routerLinkUrl : location.path()" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact:true}" [class.no-route]="!item.routerLinkUrl">
      <mat-icon class="icon" matListItemIcon>{{ item.icon }}</mat-icon>
      <div class="text" matListItemTitle>{{ item.title }}</div>

      <!-- use below if children is required -->
      <!-- <div class="badge">
        <ng-content select="td-notification-count" #badge></ng-content>
      </div>
      <i *ngIf="children.children.length != 0" class="material-icons mat-dark dropdown">arrow_drop_down</i> -->
    </a>
  </span>
</mat-list-item>
}


<ul [@closeState]="closeState" class="children" #children>
  <ng-content></ng-content>
</ul>