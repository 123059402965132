import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { reducer, State } from './customer.reducer';

export interface ICustomersState {
  customers: State;
}

const customersState: ActionReducerMap<ICustomersState> = {
  customers: reducer
};

export const CUSTOMERS_STATE_NAME = 'CUSTOMERS';
export const CUSTOMERS_STATE = new InjectionToken<ActionReducerMap<ICustomersState>>(CUSTOMERS_STATE_NAME,
  { factory: () => customersState });
